import $ from 'jquery'
import { DateTime } from 'luxon'
import { debounce } from 'lodash'
import { map } from 'lodash'
import {AjaxSync} from "./ajax_utilities";

export default class ScheduleHelpers {
  static $select = $('select#frequency')

  static clear_all () {
    this.clear_days_of_week()
    this.clear_days_of_month()
    this.clear_months()
  }

  static clear_days_of_week () {
    $('p.frequency_weekly input.recurring').val([])
  }

  static clear_days_of_month () {
    $('p.frequency_monthly input.recurring').val([])
  }

  static clear_months () {
    $('p.frequency_yearly input.recurring').val([])
  }

  static last_day_of_month () {
    this.clear_all()

    this.$select.val('Monthly')
    this.$select.trigger('change')
    $('p.frequency_monthly input.recurring').val([28, 29, 30, 31])  // Last day of month must be one of these
    $('input#bysetpos').val(-1)   // Pick the last one
  }

  static first_working_day_of_month () {
    this.clear_all()

    this.$select.val('Monthly')
    this.$select.trigger('change')
    $('input#bysetpos').val(1)
    $('p.frequency_weekly input.recurring').val(['MO', 'TU', 'WE', 'TH', 'FR'])
  }

  static last_working_day_of_month () {
    this.clear_all()

    this.$select.val('Monthly')
    this.$select.trigger('change')
    $('input#bysetpos').val(-1)
    $('p.frequency_weekly input.recurring').val(['MO', 'TU', 'WE', 'TH', 'FR'])
  }

  static refreshNextRunTime () {
    return debounce(() => {
      const rruleOptions = this.getRruleOptions()
      if ($('input.error').length === 0) {
        AjaxSync({
          url: app.CACHE.URL_ADMIN + 'scheduled_reports/all/getNextInterval',
          data: rruleOptions,
          method: 'POST'
        }, { done: this.displayNextInstance });
      }
    }, 250)
  }

  static displayNextInstance (response) {
    if (typeof response === 'string') {
      response = JSON.parse(response)
    }
    const locale = {...DateTime.DATE_MED_WITH_WEEKDAY, ...DateTime.TIME_24_SIMPLE}
    const nextRunTime = map(
      response.data.next,
      (dt, i) => {
        const interval = DateTime.fromISO(dt)
        let text = interval.toLocaleString(locale);

        if (response.data.range[i][0] === null) {
          text += ` (Data from before ${DateTime.fromISO(response.data.range[i][1]).toLocaleString(locale)})`;
        } else if (response.data.range[i][0] !== '-') {
          text += ` (Data from ${DateTime.fromISO(response.data.range[i][0]).toLocaleString(locale)}`+
              ` to ${DateTime.fromISO(response.data.range[i][1]).toLocaleString(locale)})`;
        } else {
          text += ' - Unknown range (see error above)';
        }

        return text;
      }
    ).join('<br>')

    $('span#next_run_time').html(nextRunTime)
  }

  static getRruleOptions () {
    const selectedMonths = [],
      selectedDays = [],
      selectedWeekdays = []
    const $monthSelectors = $('input[name^=bymonth]:checked').not('input[name^=bymonthday]:checked'),
      $dayOfMonthSelectors = $('input[name^=bymonthday]:checked'),
      $weekdaySelectors = $('input[name^=byweekday]:checked')
    const dataRangeFrom = $('input#date_from').val(),
      dataRangeTo = $('input#date_to').val()
    const operatorID = $("#operator_id").val();

    const now = DateTime.now();
    const hour = $('input#byhour').val();
    const minute = $('input#byminute').val()
    const second = $('input#bysecond').val()

    const freq = $('select#frequency').val()
    const interval = Number.parseInt($('input#interval').val()) || 1
    const bysetpos = Number.parseInt($('input[name=bysetpos]').val())
    const byhour = hour === '' ? [] : [hour]
    const byminute = minute === '' ? [] : [minute]
    const bysecond = second === '' ? [] : [second]

    const dtStartField = $('input#dtstart');
    let dtstart = DateTime.fromFormat(dtStartField.val(), 'd-MM-yyyy');
    if (!dtstart.isValid) {
      dtstart = now;
      dtStartField.val(dtstart.toFormat('d-MM-yyyy'));
    }

    $monthSelectors.each((_, f) => selectedMonths.push(Number.parseInt(f.value)))
    $dayOfMonthSelectors.each((_, f) => selectedDays.push(Number.parseInt(f.value)))
    $weekdaySelectors.each((_, f) => selectedWeekdays.push(f.value))

    const rruleOptions = {
      dtstart: dtstart.toISODate()
    }
    if (freq !== '') {
      rruleOptions.freq = freq.toUpperCase()
    }
    if (interval !== '') {
      rruleOptions.interval = interval
    }
    if (bysetpos !== '' && bysetpos !== 0) {
      rruleOptions.bysetpos = bysetpos
    }
    if (byhour !== []) {
      rruleOptions.byhour = byhour
    }
    if (byminute !== []) {
      rruleOptions.byminute = byminute
    }
    if (bysecond !== []) {
      rruleOptions.bysecond = bysecond
    }
    if (selectedMonths.length > 0) {
      rruleOptions.bymonth = selectedMonths
    }
    if (selectedDays.length > 0) {
      rruleOptions.bymonthday = selectedDays
    }
    if (selectedWeekdays.length > 0) {
      rruleOptions.byday = selectedWeekdays
    }
    if (operatorID) {
      rruleOptions.operator_id = operatorID
    }
    rruleOptions.from = dataRangeFrom;
    rruleOptions.to = dataRangeTo;
    return rruleOptions
  }
}