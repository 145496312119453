import { TcoMisMilCommentAddVehicleActivity } from '../modals/tacho/tco_mis_mil_comment_add_veh_activity'
import { TcoMisMilCommentEditVehicleActivity } from '../modals/tacho/tco_mis_mil_comment_edit_veh_activity'
import { AjaxSync } from '../shared/ajax_utilities'
import { DateTime } from 'luxon'
import { buildPipedUrlParametersFromNameValuePairs } from '../shared/report-parameters'
import { TachoMissingMileage_attachActivityChart } from '../list/tco_mis_mil'

$(() => {
  new TachoVehicleActivityBehaviour($('#tacho_vehicle_activity'))
});

class TachoVehicleActivityBehaviour
{
  #$table

  constructor ($table) {
    this.#$table = $table

    new TcoMisMilCommentAddVehicleActivity();
    new TcoMisMilCommentEditVehicleActivity();

    this.#attachBehaviours()
  }

  #attachBehaviours()
  {
    const $table = this.#$table;

    app.DOM.form.find('#toggle_cumulative').on('click', function() {

      // change button state
      $(this).find('span.instance').toggleClass('hidden');
      $(this).find('span.cumulative').toggleClass('hidden');
      // change table view
      $table.find('span.instance').toggleClass('hidden');
      $table.find('span.cumulative').toggleClass('hidden');
    })

    app.DOM.form.find('#toggle_tails').on('click', function() {

      // change button state
      $(this).toggleClass('button-blue button-red');
      $(this).find('span.show_tails').toggleClass('hidden');
      $(this).find('span.hide_tails').toggleClass('hidden');

      // change table view
      $table.find('span.show_tails').toggleClass('hidden');
      $table.find('span.hide_tails').toggleClass('hidden');
      $table.find('tr.tails').toggleClass('hidden');
    });

    if( $table.find('tr.Secondary').length === 0 ) {
      app.DOM.form.find('#slot-container').addClass('hidden');
      return;
    }

    app.DOM.form.find('#slot-container input').click(this.#toggleEventsFromSlots.bind(this));
  }

  #toggleEventsFromSlots(event)
  {
    const cssClass = event.currentTarget.value;

    console.log(cssClass);

    const $rowsToChange = this.#$table.find(`tr.${cssClass}`)

    if (event.currentTarget.checked) {
      $rowsToChange.removeClass('hidden');
    } else {
      $rowsToChange.addClass('hidden');
    }
  }
}

window.addEventListener('ModalConfirm', (e) => {
  let $modalContainerElement, targetURL;
  switch(e.modalId) {
    case 'tco_mis_mil_comment_add':
      $modalContainerElement = app.MODAL_CORE.MODAL_CLASSES[e.modalId].MODAL_CONTAINER_EL;
      targetURL = app.CACHE.URL_AJAX + 'tacho_missing_mileage_comment_add';
      break;
    case 'tco_mis_mil_comment_edit':
      $modalContainerElement = app.MODAL_CORE.MODAL_CLASSES[e.modalId].MODAL_CONTAINER_EL;
      targetURL = app.CACHE.URL_AJAX + 'tacho_missing_mileage_comment_update';
      break;

    default:
      console.warn(`Unrecognised modal list.js modal handler modal ID ${e.modalId}`);
      return;
  }

  e.stopPropagation();

  save(targetURL, $modalContainerElement);

  app.MODAL_CORE.closeModal(e.modalId);
});

function save(targetURL, $modal)
{
  let data = {};
  $modal.find('input,textarea,select')
    .serializeArray()
    .forEach(o => {
      data[o.name] = o.value
    });

  AjaxSync({
    url: targetURL,
    data: data,
    method: 'POST',
  }, {
    done: (res) => {
      window.location.reload();
    }
  });
}

export function TachoMissingMileage_load_information_into_modal($modal) {
  return function (data) {
    const row = data.data;
    const start = DateTime.fromISO(row.start),
      end = DateTime.fromISO(row.end);
    let format;
    if (start.hasSame(end, 'day')) {
      format = DateTime.TIME_24_SIMPLE
    } else {
      format = {...DateTime.DATE_MED, ...DateTime.TIME_24_SIMPLE}
    }

    const li = `<li class="gap">` +
      `<span class="id_reg ${row.reg_country} id_reg_${row.reg_country}">${row.reg}</span>&nbsp;` +
      `${row.diff} km between ${start.toLocaleString(format)} and ${end.toLocaleString(format)}` +
      `</li>`;
    $modal.find('ul.affected_gaps').html(li)
    TachoMissingMileage_attachActivityChart(row.tacho_vehicle_id, start, end, $modal)
  };
}
